<template>
   <div class="trainingJudge">
     <div class="sr-box">
        <div class="sr-box-a">
          <span>判断结论</span>
          <el-input class="sr" v-model="datas.S_OP_Json.S_OP_IT_conclusion" placeholder="请输入内容"></el-input>
        </div>
        <div class="sr-box-a">
          <span>训练类型</span>
          <el-input class="sr" v-model="datas.S_OP_Json.S_OP_IT_type" placeholder="请输入内容"></el-input>
        </div>
        <div class="sr-box-a">
          <span>起止时间</span>
          <el-date-picker
              class="sr"
              v-model="value1"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="sr-box-a">
          <span>训练目标</span>
          <el-input class="sr" v-model="datas.S_OP_Json.S_OP_IT_target" placeholder="请输入内容"></el-input>
        </div>
      </div>
     <h3>顾客签名</h3>
     <div class="gkqm">
       <div>
         在此处签名
       </div>
     </div>
     <div>
       <el-button type="primary" @click="$router.back(-1)">返回</el-button>
       <el-button type="warning">清除</el-button>
       <el-button @click="save" type="primary">保存并提交</el-button>
     </div>
   </div>
</template>

<script>
import pf from '@/publicFn/baseFn'
import axios from "@/api/axios"
export default {
  name: "trainingJudge",
  data() {
    return {
      input: '',
      value1: '',
      datas: {
        S_OP_ChooseId: "",
        S_OP_CustomerId: "",
        S_OP_ExpertId: "00000000-0000-0000-0000-000000000000",
        S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
        S_OP_ID: "00000000-0000-0000-0000-000000000000",
        S_OP_Json: {
          S_OP_IT_CoverOD: "",
          S_OP_IT_CoverOS: "",
          S_OP_IT_EndDate: "",
          S_OP_IT_IO_AllId: "",
          S_OP_IT_StartDate: "",
          S_OP_IT_conclusion: "",
          S_OP_IT_target: "",
          S_OP_IT_type: "",
        },
        S_OP_Model: "S_OP_InspectionTraining190222",
        S_OP_Time: "",
        S_OP_Type: "InspectionTraining",
        S_OP_XML: ""
      }
    }
  },
  watch: {
    "value1": function (n, o) {
      this.datas.S_OP_Json.S_OP_IT_StartDate = n[0]
      this.datas.S_OP_Json.S_OP_IT_EndDate = n[1]
    }
  },
  created() {
    this._api.training.getTrainingJudge()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        res.GetListResult[0].S_OP_Json = pf.decoding(res.GetListResult[0].S_OP_Json)
        this.datas = res.GetListResult[0]
        this.value1 = [this.datas.S_OP_Json.S_OP_IT_StartDate, this.datas.S_OP_Json.S_OP_IT_EndDate]
      }
    })
  },
  methods: {
    save() {
      this.datas.S_OP_ChooseId = this.$store.state.physicianVisits.xzConsulting
      this.datas.S_OP_CustomerId = this.$store.state.users.CsUser.CustomerId
      let dp = JSON.parse(JSON.stringify(this.datas))
      dp.S_OP_Json = pf.conversionJson(dp.S_OP_Json)
      axios.post(pf.getUrl('S_ALL_Normal_Save'), {
        token: {
          sToken: '71F93B019CCB8EA1136D9DD94FB134E4',
          TimeSpan: '1618198660'
        },
        o: dp
      })
      .then(res => {
        if (res.UpdateResult) {
          this.$alert('保存成功！', '提示')
        }else {
          this.$alert('保存失败！', '提示')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
   .trainingJudge {
     width: 100vw;
     height: 87vh;
     overflow: hidden;
   }
   .sr-box {
     width: 90vw;
     padding: 5vh 5vw;
     display: flex;
     flex-wrap: wrap;
     .sr-box-a {
       display: flex;
       align-items: center;
       justify-content: start;
       margin: .2rem;
       .sr {
         width: 18vw;
         margin-left: .1rem;
       }
     }
   }
   h3 {
     width: 90vw;
     padding: 0 5vw;
     text-align: left;
   }
   .gkqm {
     width: 100vw;
     height: 40vh;
     border-top: 1px solid #5a5959;
     position: relative;
     div {
       position: absolute;
       width: 100%;
       height: 100%;
       display: flex;
       align-items: center;
       justify-content: center;
       opacity: 0.3;

       font-weight: bold;
       font-size: 50px;
     }
   }
</style>